<template>
    <div>
      <div v-if="this.entriesChecked.length > 1  && (this.currentEntry.service === undefined && !resetedService)" class="info-container">
        <p class="text-reset">{{ $t("activities.multiple.edit.service") }}</p>
        <RewriteButton :on-click="resetService"/>
      </div>
      <b-row v-else>
        <b-col cols="8">
          <h4 class="subtitle-form mb-2">{{ $t("components.services.service") }}</h4>
          <Dropdown
            ref="dropdown"
            class="shape-custom-select"
            :options="services"
            label="name"
            :value="this.selectedService"
            @input="service => setSelectedService(service)"
        />
        </b-col>
        <b-col cols="4">
          <h4 class="subtitle-form mb-2">{{ $t("components.services.not_to_bill") }}</h4>
          <b-form-checkbox 
            size="sm" 
            v-model="notToBill" 
            @change="setNotToBill" 
          />
        </b-col>
      </b-row>
      <!-- <b-row>
        BILLING DATE Input
        <b-col cols="6" class="text-infos shape-first-cell whitout-bg">
          <div class="inputs-params">
            <input type="date" id="example-datepicker" ref="billingDate" v-model="billingDate" @change="setBillingDate()" placeholder="yyyy-mm-dd" />
          </div>
        </b-col>
        Reset Btn
        <b-col cols="6" class="text-infos shape-second-cell whitout-bg">
          <RewriteButton :on-click="resetBillingDate"/>
        </b-col>
      </b-row> -->
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
  import LoadFormPartMixin from "@/mixins/LoadFormPartMixin";
  import Dropdown from "@/components/activity/edit/Dropdown.vue";
  import RewriteButton from "@/components/commons/RewriteButton.vue";
  
  export default {
    name: "edit-prestation",
    mixins: [DetailUpdateMixin, LoadFormPartMixin],
    data() {
      return {
        selectedService: {},
        notToBill: false,
        loadComponent: false,
        resetedService: false,
        resetedBillingDate: false,
        billingDate: "",
      };
    },
    components: {
     Dropdown,
     RewriteButton,
    },
    computed: {
      ...mapGetters({
        services: "services",
        entriesChecked: "activity/entriesChecked",
      }),
      machines(){
        return this.currentEditedEntry.machines || this.currentEntry.machines
      },
      activityType(){
        return this.currentEditedEntry.activityType || this.currentEntry.activityType
      }
    },
    methods: {
        async init(){
            this.loadComponent = false

            await this.getServices()

            if (this.$route.name === "activity.edit") {
                //initialise si en edit
                this.notToBill = this.currentEntry.notToBill

            }

            this.loadComponent = true
        },
        validateForm: function() {
            this.isValid = true;
            return this.loadComponent;
        },
        async getServices() {
            if (!this.activityType && (!this.machines || !this.machines.length)) {
            return; // Ne fais rien si les valeurs ne sont pas prêtes
            }
    
            try {
            await this.$store.dispatch("getServices", {
                activityType: this.activityType,
                machines: this.machines,
            });
    
            if (this.$route.name === "activity.edit") {
                //service par default
                this.selectedService = this.services.find(s => s.id = this.currentEntry.service);

            }
            this.setDefaultService(); 
           
            } catch (error) {
            console.error("Erreur lors de la récupération des services", error);
            }
        },
        setDefaultService() {
            // Si la prestation actuellement sélectionnée n'est pas dans la liste des prestations, on réinitialise la sélection
            if (this.selectedService?.id && this.services.find(service => service.id === this.selectedService.id) === undefined) {
              this.selectedService = {}
            }
            // On présélectionne la prestation par défaut
            let defaultService = this.services.find(service => service.default === true);
            if (!defaultService && this.services.length === 1) {
              // S'il y'a une seule prestation renvoyée, elle est présélectionnée
              defaultService = this.services[0];
            }
            // La présélection est faite s'il y'avait pas de prestation préalablement sélectionnée
            if (defaultService && (!this.selectedService || Object.keys(this.selectedService).length === 0 )) {
              this.selectedService = defaultService;
            }
        },
        setSelectedService(value) {
            this.selectedService = value;
        },
        setNotToBill(value) {
            this.$store.commit("activity/setEditedEntryValue", { notToBill: value });
        },
        setBillingDate(){
            this.$store.commit("activity/setEditedEntryValue", { billingDate: this.billingDate });
        },
        resetService(){
          this.resetedService = true
          this.$store.commit("activity/setEditedEntryValue", { service: null });
        },
        resetBillingDate (){
          this.resetedBillingDate = true
          this.$store.commit("activity/setEditedEntryValue", { billingDate : null });
        }
    },
    watch: {
      services: {
        handler() {
          this.setDefaultService(); // Mettre à jour la valeur par défaut quand les services changent
        },
        deep: true,
      },
      selectedService: {
        handler() {
          if (this.selectedService?.id) {
            this.$store.commit("activity/setEditedEntryValue", { service: this.selectedService.id });
          }
        }
      }
    },
    mounted() {
      this.init()
    },
  };
  </script>
  